// Step 1. Import React
import * as React from 'react'
import Layout from '../components/layout'

// Step 2. Define your component
const AboutPage = () => {
    return (
        <Layout pageTitle="💁🏻‍♀️ About Me">
            <p>🌊 Hello!</p>
            <p>Stine here. I'm a 👩🏻‍💻 technical writer, 🖍 poet, and 📚 translator (🇰🇷 > 🇺🇸) in 🍎 Queens, NY by way of 🍑 Atlanta, GA and 🍐 Cheonan, South Korea.</p>
            <p>I'm building this site to learn more about <a href="https://jamstack.org">JAMstack</a>-based web development and to explore the web as performance space and installation space.</p>
            <p>You can visit my personal site at <a href="www.gregorspamsa.com">gregorspamsa.com</a>.</p>
        </Layout>
    )
}

// Step 3. Export your component
export default AboutPage